import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LocalStorageService {
  static readonly jwtKey = 'workbase_jwt';

  public setItem<T>(key: string, value: T): BehaviorSubject<string> {
    const stringified = JSON.stringify(value);
    localStorage.setItem(key, stringified);
    return new BehaviorSubject(stringified);
  }

  public getItem<T>(
    key: string,
    itemMapper: (item: string | null) => T
  ): BehaviorSubject<T> {
    return new BehaviorSubject(itemMapper(localStorage.getItem(key)));
  }

  public removeItem(key: string): Observable<string> {
    localStorage.removeItem(key);
    return new BehaviorSubject(key);
  }
}
