import { Icon } from "@shared/modules/icons/shared/icon";
import {
  CHECK_CIRCLE, CLOSE,
  EMAIL_ICON,
  HIGHLIGHT_OFF,
  OFFICE,
  PASSWORD_HIDDEN,
  PASSWORD_VISIBLE, PERSON
} from "@shared/modules/icons/shared/icon-svg";
import {
  PASSWORD_HIDDEN_NAME,
  PASSWORD_VISIBLE_NAME
} from "@shared/modules/icons/shared/icon-name";

export class IconUtils {
  static EMAIL_ICON = new Icon('email', EMAIL_ICON);
  static HIGHLIGHT_OFF = new Icon('highlight_off', HIGHLIGHT_OFF);
  static CHECK_CIRCLE = new Icon('check_circle', CHECK_CIRCLE);
  static OFFICE = new Icon('office', OFFICE);
  static PASSWORD_VISIBLE = new Icon(PASSWORD_VISIBLE_NAME, PASSWORD_VISIBLE);
  static PASSWORD_HIDDEN = new Icon(PASSWORD_HIDDEN_NAME, PASSWORD_HIDDEN);
  static CLOSE = new Icon('close', CLOSE);
  static PERSON = new Icon('person', PERSON);

  static ALL_ICONS = [
    IconUtils.EMAIL_ICON,
    IconUtils.HIGHLIGHT_OFF,
    IconUtils.CHECK_CIRCLE,
    IconUtils.OFFICE,
    IconUtils.PASSWORD_VISIBLE,
    IconUtils.PASSWORD_HIDDEN,
    IconUtils.CLOSE,
    IconUtils.PERSON
  ];
}
