import {Component, OnInit} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { AuthService } from './+auth/shared/auth.service';
import { UrlParamHandlerServiceService } from '@shared/modules/url-params-handler/shared/url-params-handler-service/url-param-handler-service.service';
import { Meta } from '@angular/platform-browser';
import {environment} from "@env/environment";

@Component({
  styles: [''],
  selector: 'wb-root',
  template: '<router-outlet></router-outlet>',
})
export class AppComponent implements OnInit {
  constructor(
    translate: TranslateService,
    private authService: AuthService,
    private urlParamHandlerServiceService: UrlParamHandlerServiceService,
    private metaTagService: Meta,
  ) {
    this.setTranslate(translate);
    this.checkURLForQueryParams();
  }

  ngOnInit() {
    console.log(environment);
    if (!environment.indexableInSearchEngines) {
      this.metaTagService.addTag({
        name: 'robots',
        content: 'noindex, nofollow',
      })
    }
  }

  private setTranslate(translate: TranslateService): void {
    translate.setDefaultLang(environment.defaultLanguage);
    translate.use(environment.defaultLanguage);
  }

  private checkURLForQueryParams(): void {
    const redirectURL = this.urlParamHandlerServiceService.getUrlParam(
      UrlParamHandlerServiceService.redirectURLParam
    );
    if (redirectURL) this.authService.setCallbackUrlFromNextParam(redirectURL);

    const shouldDelAuthData = !!this.urlParamHandlerServiceService.getUrlParam(
      UrlParamHandlerServiceService.logoutParam
    );
    if (shouldDelAuthData) this.authService.clearData();

    this.authService.isOidcSignin =
      !!this.urlParamHandlerServiceService.getUrlParam(
        UrlParamHandlerServiceService.oidcSigninParam
      );
  }
}
