import {NavigationExtras, Router} from '@angular/router';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { filter, mergeMap } from 'rxjs/operators';

import { User } from './auth.model';
import { LocalStorageService } from './local-storage.service';
import {HttpRequest} from "@angular/common/http";

@Injectable({ providedIn: 'root' })
export class AuthService {
  user!: User;
  readonly accessTokenKey = 'sessionid';
  readonly refreshTokenKey = 'refresh';
  readonly webAppAccessTokenKey = 'access_token';
  readonly webAppRefreshTokenKey = 'refresh_token';
  private readonly initiateOAuthFlowKey = 'init_oauth';

  private _callbackUrlFromNextParam!: string;
  private _isOidcSignin$ = new BehaviorSubject(false);

  constructor(
    private router: Router,
    private cookieService: CookieService,
    private lsService: LocalStorageService
  ) {
    this._isOidcSignin$
      .pipe(
        filter((isOidc) => !!isOidc),
        mergeMap(() => this.authError())
      )
      .subscribe();
  }

  get accessToken(): string {
    return this.cookieService.get(this.accessTokenKey) || '';
  }

  get refreshToken(): string {
    return this.cookieService.get(this.refreshTokenKey) || '';
  }

  get callbackUrlFromNextParam(): string {
    return this._callbackUrlFromNextParam;
  }

  get isOidcSignin(): boolean {
    return this._isOidcSignin$.value;
  }

  set isOidcSignin(isOidcSignin: boolean) {
    this._isOidcSignin$.next(isOidcSignin);
  }

  initiateOAuthFlowOnURL(url: string): string {
    return `${url}?${this.initiateOAuthFlowKey}`;
  }

  setUser(user: User): void {
    this.user = user;
  }

  loginSuccess(): void {
    if (this.isOidcSignin && !!this._callbackUrlFromNextParam) {
      this.redirectToCallbackApp();
    } else {
      const searchParams = new URLSearchParams(window.location.search);
      const redirectUrl =  searchParams.get('redirect')
      this.router.navigate(['/companies'], {
        queryParams: { redirect: redirectUrl || 'false', queryParamsHandling: 'merge' },
      });
    }
  }

  clearData(): void {
    this.cookieService.delete(this.accessTokenKey);
    this.cookieService.delete(this.refreshTokenKey);
    this.cookieService.delete(this.webAppAccessTokenKey);
    this.cookieService.delete(this.webAppRefreshTokenKey);
    this.lsService.removeItem(LocalStorageService.jwtKey);
  }

  logoutRedirection(): void {
    this.clearData();
    const searchParams = new URLSearchParams(window.location.search);
    const redirectUrl =  searchParams.get('redirect')
    this.router.navigate(['/login'], {
      queryParams: { redirect: redirectUrl || 'false', queryParamsHandling: 'merge' },
    });
}

  logout(): void {
    // waiting for api to be ready
    // this.authRepository
    //   .logout()
    //   .pipe(finalize(() => this.authError()))
    //   .subscribe();
    this.logoutRedirection();
  }

  permissionDenied(): void {
    this.router.navigate(['/']);
  }

  setCallbackUrlFromNextParam(nextUrlRedirect: string): void {
    this._callbackUrlFromNextParam = nextUrlRedirect;
  }

  redirectToCallbackApp(): void {
    window.location.href = this.callbackUrlFromNextParam;
  }

  authError(request?: HttpRequest<unknown>): Observable<boolean> {
    this.clearData();
    const searchParams = new URLSearchParams(window.location.search);
    const redirectUrl =  searchParams.get('redirect')
    const baseQueryParams = { redirect: redirectUrl || 'false' };
    const navigationExtras: NavigationExtras = {
      queryParams: baseQueryParams,
      queryParamsHandling: 'merge'
    };

    if (request?.params) {
      navigationExtras.queryParams = { ...baseQueryParams, ...request.params };
    }

    this.router.navigate(['/login'], navigationExtras);

    return of(false);
  }
}
