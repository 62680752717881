import { Injectable } from '@angular/core';
import { Observable, catchError, throwError } from 'rxjs';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';

import { LocalStorageService } from './local-storage.service';


@Injectable()
export class JWTTokenInterceptor implements HttpInterceptor {
  private readonly jwtTokenKey = 'Bearer';
  private readonly authHeaderKey = 'Authorization';
  private readonly excludedSubstring = 'authn/login/company'; // Substring to check in the URL
  private readonly excludedFromJwt = ['en.json', 'de.json'];

  constructor(private lsService: LocalStorageService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    // This interceptor is used by i18n module to fetch translations, avoid adding JWT token to these requests
    if (this.excludedFromJwt.some((x) => request.url.includes(x))) {
      return next.handle(request).pipe(catchError((err) => throwError(() => err)));
    }

    // Existing logic to add JWT token
    const jwt = this.lsService.getItem<string | null>(
      LocalStorageService.jwtKey,
      (x) => (x ? JSON.parse(x) : x)
    ).value;

    const requestWithJwt = (
      jwt
        ? request.clone({
          headers: request.headers.set(
            this.authHeaderKey,
            `${this.jwtTokenKey} ${jwt}`
          ),
        })
        : request
    )

    const clonedRequest = request.url.includes(this.excludedSubstring) ? requestWithJwt.clone({withCredentials: false}) : requestWithJwt.clone({
      withCredentials: false,
    });

    return next
      .handle(clonedRequest)
      .pipe(catchError((err) => throwError(() => err)));
  }
}
