import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';

import { TranslateModule } from '@ngx-translate/core';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { TitleModule } from '@shared/modules/title/title.module';
import { IconsModule } from '@shared/modules/icons/icons.module';
import { ErrorInterceptor } from './+auth/shared/auth-error.interceptor';
import { JWTTokenInterceptor } from './+auth/shared/jwt-token.interceptor';
import { TranslateConfig } from '@shared/modules/translate/translate.factory';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    RouterModule,
    HttpClientModule,
    TitleModule.forRoot({ suffix: 'title.suffix' }),
    TranslateModule.forRoot(TranslateConfig),
    AppRoutingModule,
    BrowserAnimationsModule,
    IconsModule,
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'outline' },
    },
    { provide: HTTP_INTERCEPTORS, useClass: JWTTokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
