import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { IconUtils } from "@shared/modules/icons/shared/icon.utils";

@NgModule()
export class IconsModule {
  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
  ) {
    IconUtils.ALL_ICONS.forEach(({iconName, iconSvg}) => {
      this.matIconRegistry.addSvgIconLiteral(iconName, this.domSanitizer.bypassSecurityTrustHtml(iconSvg));
    });
  }
}
