import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UrlParamHandlerServiceService {
  static readonly redirectURLParam = 'next';
  static readonly oidcSigninParam = 'oidc_signin';
  static readonly logoutParam = 'should_logout';

  getUrlParam(paramName: string): string | null {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    return urlParams.get(paramName) || null;
  }
}
